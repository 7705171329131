<!-- 学员信息 -->
<template>
    <div class="studentNews">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员信息</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-left: 21px;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:98%;flex: 1;" height="650" >
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证号" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="username" label="学员姓名" sortable="custom"  align="center" width="120">
            </el-table-column>
            <el-table-column prop="pinyin_name" label="拼音名" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="idcard" label="身份证号" sortable="custom" align="center" width="200">
            </el-table-column>
            <!-- <el-table-column prop="gender" label="性别" sortable="custom" align="center" width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.gender==1">男</span>
                    <span v-else-if="scope.row.gender==2">女</span>
                </template>
            </el-table-column>
            <el-table-column prop="birthdate" label="出生日期" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="nation" label="民族" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="nation2" label="国籍" sortable="custom" align="center" width="100">
            </el-table-column> -->
            <el-table-column prop="apply_category" label="考试大类" sortable="custom" align="center" width="300">
            </el-table-column>
            <el-table-column prop="exam2_date" label="考试时间" sortable="custom" align="center" width="220">
            </el-table-column>
            <el-table-column prop="exam_address" label="考场" sortable="custom" align="center" width="220" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="exam_result" label="成绩" sortable="custom" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.exam_result==1"></span>
                    <span v-else-if="scope.row.exam_result==2">通过</span>
                    <span v-else-if="scope.row.exam_result==3">未通过</span>
                </template>
            </el-table-column>
            <el-table-column prop="org_name" label="机构名称" sortable="custom" align="center" width="230">
            </el-table-column>
            <el-table-column prop="examarea_name" label="安排状态" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course_name" label="学科" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_level" label="等级值" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="status" label="考试状态" sortable="custom" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">草稿</span>
                    <span v-else-if="scope.row.status==2">报考</span>
                    <span v-else-if="scope.row.status==3">准考</span>
                    <span v-else-if="scope.row.status==4">考试ok</span>
                    <span v-else-if="scope.row.status==5">取消</span>
                    <span v-else-if="scope.row.status==6">退款</span>
                    <span v-else-if="scope.row.status==7">延期</span>
                    <span v-else="scope.row.status==8">缺考</span>
                </template>
            </el-table-column>
            <el-table-column prop="certificatecopy_price" sortable="custom" label="副证费" align="center" width="130">
            </el-table-column>
        </el-table> 
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>   
    </div>
</template>

<script>
    export default{
        data() {
            return{
               currentPage: 1,
               currentLimit: 20,
               total: 0,
               tableData:[], 
            }
        },
        created() {
            this.getList()
        },
        methods:{
            getList(){
              this.$request({
                  url:'/api/student/student_history',
                  method:'POST',
                  data:{
                    id:this.$route.query.id,
                    page:this.currentPage,
                    limit:this.currentLimit
                  }
              }).then(res=>{
                  if(res.code==1){
                      console.log(res)
                      this.tableData=res.data.list
                      this.total=res.data.count
                  }
              })  
            },
            handleSizeChange(val) {
                 this.currentLimit=val
                 this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            // 姓名排序
            fn2(a, b){
                return a.username.localeCompare(b.username)
            }
        }
    }
</script>

<style scoped>
    .studentNews{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
